import IconAlertTriangleSvg from '@assets/icon-alert-triangle.svg';
import { SVG_SIZES } from '@utils/constants';
import styles from './css';

type IconProps = {
  color?: 'black600' | 'blue' | 'pink' | 'red' | 'white';
  size?: 'md' | 'sm' | 'xs';
};

const IconAlertTriangle = ({ color = 'red', size = 'md' }: IconProps) => {
  const style = {
    height: SVG_SIZES[size],
    width: SVG_SIZES[size],
  };

  return (
    <>
      <IconAlertTriangleSvg className={`color-${color}`} style={style} />
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default IconAlertTriangle;
