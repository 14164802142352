import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  :global(.color-red) {
    :global(path) {
      fill: ${COLORS.red600};
      opacity: 1;
    }
  }
  :global(.color-blue) {
    :global(path) {
      fill: ${COLORS.purple700};
      opacity: 1;
    }
  }
  :global(.color-black600) {
    :global(path) {
      fill: ${COLORS.black600};
      opacity: 1;
    }
  }
  :global(.color-pink) {
    :global(path) {
      fill: ${COLORS.pink500};
      opacity: 1;
    }
  }
  :global(.color-white) {
    :global(path) {
      fill: ${COLORS.white};
      opacity: 1;
    }
  }
`;

export default styles;
