import { global } from 'styled-jsx/css';
import { BREAKPOINT } from '@utils/constants';

export default global`
  .hafh-toast {
    &.Toastify__toast-container {
      width: 335px;

      @media (min-width: ${BREAKPOINT.mobile}px) {
        width: 470px;
      }
    }

    &.Toastify__toast-container--bottom-center {
      bottom: var(--toastify-toast-bottom);
      left: 50%;
      transform: translateX(-50%);
    }

    .Toastify__toast {
      align-items: center;
      min-height: initial;
      margin-bottom: 15px;
      
      padding: 12px;
      border-radius: 8px;
    }
  }
`;
