import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .toast-body {
    display: flex;
    align-items: center;

    &--alert {
      background-color: ${COLORS.pink500};
    }

    &--error {
      background-color: ${COLORS.red600};
    }

    &--notify {
      background-color: ${COLORS.purple600};
    }

    &--success {
      background-color: ${COLORS.green500};
    }

    &__icon {
      margin-right: 8px;
    }

    &__label {
      flex: 1;
      font-size: 14px;
      color: ${COLORS.white};
      font-weight: 700;
    }
  }
`;

export default styles;
