import css from 'styled-jsx/css';

import COLORS from '@utils/colors';

const styles = css`
  :global(.icon-arrow.color-green500) {
    :global(path) {
      fill: ${COLORS.green500};
    }
  }
  :global(.icon-arrow.color-primary) {
    :global(path) {
      fill: ${COLORS.primary};
    }
  }
  :global(.icon-arrow.color-white) {
    :global(path) {
      fill: ${COLORS.white};
    }
  }
`;

export default styles;
