import { ReactElement } from 'react';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

type Props = {
  label: ReactElement | string;
  shouldTranslate?: boolean;
  type: string;
};

const ToastBody = ({ label, shouldTranslate = false, type }: Props) => {
  const { t } = useTranslation('toast');

  const _shouldTranslate = typeof label === 'string' && shouldTranslate;

  return (
    <div className={`toast-body toast-body--${type}`} data-testid="toast">
      <span className="toast-body__label">
        {_shouldTranslate ? t(label as string) : label}
      </span>

      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default ToastBody;
