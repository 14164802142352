import { ReactElement } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import ToastBody from '@atoms/ToastBody';
import ToastClose from '@atoms/ToastClose';
import IconAlertTriangle from '@components/svg-icons/IconAlertTriangle';
import IconCheckCircle from '@components/svg-icons/IconCheckCircle';
import IconInfo from '@components/svg-icons/IconInfo';
import IconSlash from '@components/svg-icons/IconSlash';
import { BREAKPOINT } from '@utils/constants';
import COLORS from './colors';


const getPosition = () =>
  window.innerWidth <= BREAKPOINT.mobile ? 'bottom-center' : 'top-center';

const toastUtils = {
  alert: (
    label: ReactElement | string,
    shouldTranslate = false,
    options?: ToastOptions
  ) => {
    toast(
      <ToastBody
        label={label}
        shouldTranslate={shouldTranslate}
        type="alert"
      />,
      {
        autoClose: 7000,
        closeButton: <ToastClose />,
        icon: <IconAlertTriangle color="white" />,
        position: getPosition(),
        style: {
          backgroundColor: COLORS.pink500,
        },

        ...options,
      }
    );
  },
  error: (
    label: ReactElement | string,
    shouldTranslate = false,
    options?: ToastOptions
  ) => {
    toast.error(
      <ToastBody
        label={label}
        shouldTranslate={shouldTranslate}
        type="error"
      />,
      {
        autoClose: 7000,
        closeButton: <ToastClose />,
        icon: <IconSlash />,
        position: getPosition(),
        style: {
          backgroundColor: COLORS.red600,
        },

        ...options,
      }
    );
  },
  notify: (
    label: ReactElement | string,
    shouldTranslate = false,
    options?: ToastOptions
  ) => {
    toast(
      <ToastBody
        label={label}
        shouldTranslate={shouldTranslate}
        type="notify"
      />,
      {
        autoClose: 7000,
        closeButton: <ToastClose />,
        icon: <IconInfo color="white" />,
        position: getPosition(),
        style: {
          backgroundColor: COLORS.purple600,
          cursor: 'pointer',
        },

        ...options,
      }
    );
  },
  success: (
    label: ReactElement | string,
    shouldTranslate = false,
    options?: ToastOptions
  ) => {
    toast.success(
      <ToastBody
        label={label}
        shouldTranslate={shouldTranslate}
        type="success"
      />,
      {
        autoClose: 4000,
        closeButton: <ToastClose />,
        icon: <IconCheckCircle color="white" />,
        position: getPosition(),
        style: {
          backgroundColor: COLORS.green500,
        },

        ...options,
      }
    );
  },
};

export default toastUtils;
