import classNames from 'classnames';
import IconCheckCircleSvg from '@assets/icon-check-circle.svg';
import { SVG_SIZES } from '@utils/constants';
import styles from './css';

type IconProps = {
  color?: 'green500' | 'primary' | 'white';
  size?: 'md' | 'sm' | 'xs';
};

const IconCheckCircle = ({ color, size = 'md' }: IconProps = {}) => {
  const style = {
    height: SVG_SIZES[size],
    width: SVG_SIZES[size],
  };

  return (
    <>
      <IconCheckCircleSvg
        className={classNames({ [`color-${color}`]: color }, 'icon-arrow')}
        style={style}
      />
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default IconCheckCircle;
