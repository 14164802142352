import { User } from 'firebase/auth';
import apiClient, { API_TYPES } from '@services/hafh/api';

type Action = {
  payload: CuratedPropertiesState;
  type: string;
};

//TODO: Add types for CuratedPropertiesState from swagger
type CuratedPropertiesState = any;

const api = apiClient(API_TYPES.API);

const initialState: CuratedPropertiesState = [];

const HAND_PICKED_PROPERTIES_ENDPOINT = 'properties/hand-picked';

export const UPDATE_CURATED_PROPERTIES =
  'hafh/curatedProperties/UPDATE_CURATED_PROPERTIES';

const curatedPropertiesReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case UPDATE_CURATED_PROPERTIES:
      return action.payload;
    default:
      return state;
  }
};

export const updateCuratedProperties = (curatedProperties = []) => ({
  payload: curatedProperties,
  type: UPDATE_CURATED_PROPERTIES,
});

export const getCuratedProperties =
  (locale: string, authUser: User) => async (dispatch) => {
    try {
      const curatedProperties = await api.get(
        HAND_PICKED_PROPERTIES_ENDPOINT,
        {},
        locale,
        authUser
      );

      if (curatedProperties) {
        dispatch(updateCuratedProperties(curatedProperties));

        return curatedProperties;
      }
    } catch (error) {
      console.error('Failed to fetch curated properties:', error);
    }
  };

export default curatedPropertiesReducer;
