import apiClient, { API_TYPES } from '@services/hafh/api';

const api = apiClient(API_TYPES.API);

const initialState = [];

export const UPDATE_RESERVATION_COUNT_RANKING =
  'hafh/reservationCountRanking/UPDATE_RESERVATION_COUNT_RANKING';

const ReservationCountRanking = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RESERVATION_COUNT_RANKING:
      return [...action.payload];
    default:
      return state;
  }
};

export const updateReservationCountRanking = (
  reservationCountRanking = []
) => ({
  payload: reservationCountRanking,
  type: UPDATE_RESERVATION_COUNT_RANKING,
});

export const getReservationCountRanking =
  (locale, regionKey = 'allRegion', authUser) =>
  async (dispatch) => {
    const url = `properties/rankings/reservation_count${
      regionKey === 'allRegion' ? '' : `?region=${regionKey}`
    }`;
    const reservationCountRanking = await api.get(url, {}, locale, authUser);
    if (reservationCountRanking) {
      dispatch(updateReservationCountRanking(reservationCountRanking));
      return reservationCountRanking;
    }
  };

export default ReservationCountRanking;
